import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'replay',
  initialState: {
    index: 0,
    playing: false,
    positions: [],
    onReplayPage: false,
    pause: false,
    userEnabledNotifications: [],
  },
  reducers: {
    setPositions(state, action) {
      state.positions = action.payload;
    },
    resetPositions(state) {
      state.positions = [];
    },
    setPlaying(state, action) {
      state.playing = action.payload;
    },
    resetPlaying(state) {
      state.playing = false;
    },
    setIndex(state, action) {
      state.index = action.payload;
    },
    resetIndex(state) {
      state.index = 0;
    },
    setOnReplayPage(state, action) {
      state.onReplayPage = action.payload;
    },
    setPause(state, action) {
      state.pause = action.payload;
    },
    resetPause(state) {
      state.pause = false;
    },
    setUserEnabledNotifications(state, action) {
      state.userEnabledNotifications = action.payload;
    },
  },
});

export { actions as replayActions };
export { reducer as replayReducer };
